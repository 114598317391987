import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, Button, Stack } from 'react-bootstrap';
import ConfirmationModal from '../ConfirmationModal';
import useBlogPostService from '../../services/blogPostService';

import './BlogPostCard.css';

const BlogPostCard = ({ post }) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { deleteBlogPost, handlePublicationStatusChange, getBlogPostById } = useBlogPostService();
  const [published, setPublished] = useState(false);
  const [blogPost, setBlogPost] = useState(post);

  const openPost = () => {
    navigate(`/post/${blogPost.id}`);
  };

  const publicationChanged = async () => {
    setPublished(!published)
    await handlePublicationStatusChange(blogPost.id, !published);
    let newBlogPost = await getBlogPostById(blogPost.id);
    setBlogPost(newBlogPost);
  };

  useEffect(() => {
    setPublished(blogPost.published);
  }, []);

  const editPost = () => {
    navigate(`/post/edit/${blogPost.id}`);
  };

  const userHasAdminRole = () => {
    return user?.['https://gluck.blog/roles']?.includes('BlogAdmin');
  };

  const deletePost = async () => {
    try {
      await deleteBlogPost(blogPost.id);
      navigate(0);
    } catch (error) {
      console.error('Error deleting blog post:', error);
    }
  };

  const handleDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteConfirm = () => {
    setShowDeleteModal(false);
    deletePost();
  };

  return (
    <div>
      <Card>
        {blogPost.imageUrl ? (
          <Card.Img
            variant="top"
            src={blogPost.imageUrl}
            className="PostImage"
          />
        ) : (
          <div className="placeholder-image" />
        )}
        <Card.Body className="d-flex justify-content-center">
          <Button
            variant="light"
            onClick={openPost}
            style={{ color: "gray", backgroundColor: "transparent", border: "none" }}
          >
            <Card.Title>{blogPost.header}</Card.Title>
          </Button>
        </Card.Body>
        {userHasAdminRole() && (
          <Card.Footer>
            <Stack direction='horizontal' gap={3}>
              <Button variant="primary" onClick={publicationChanged}>
                {blogPost.published ? 'Archive' : 'Publish'}
              </Button>
              <Button variant="warning" onClick={editPost}>
                Edit
              </Button>
              <Button variant="danger" onClick={handleDeleteConfirmation}>
                Delete
              </Button>
              <ConfirmationModal
                show={showDeleteModal}
                confirm={handleDeleteConfirm}
                close={handleDeleteCancel}
                title="Delete Blog Post"
                message="Are you sure you want to delete this blog post?"
              />
            </Stack>
          </Card.Footer>
        )}
      </Card>

    </div>
  );
};

export default BlogPostCard;