import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useBlogPostService from '../services/blogPostService';
import MDEditor from '@uiw/react-md-editor';

const BlogPost = () => {
    const { postId } = useParams();
    const navigate = useNavigate();
    const { getBlogPostById } = useBlogPostService();
    const { user } = useAuth0();
    const [blogPost, setBlogPost] = useState([]);

    useEffect(() => {
        const fetchAndSetBlogViews = async () => {
            try {
                const post = await getBlogPostById(postId);
                setBlogPost(post);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchAndSetBlogViews();
    }, []);

    const userHasAdminRole = () => {
        return user?.['https://gluck.blog/roles']?.includes('BlogAdmin');
    };

    const editPost = () => {
        navigate(`/post/edit/${blogPost.id}`);
    };

    return (
        <div className='mainContainerSinglePost'>
            <Row>
                <Col className='postContainerSinglePost'>
                    {blogPost.Header != null > 0 &&
                        <div>
                            <div data-color-mode="light">
                                <MDEditor.Markdown source={blogPost.body} />
                            </div>
                            {userHasAdminRole() && (
                                <div className="mb-1 container" data-color-mode="light">
                                    <Button variant="warning" onClick={editPost}>
                                        Edit
                                    </Button>
                                </div>
                            )}
                        </div>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default BlogPost;