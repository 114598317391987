import roadImage from '../images/road.jpg'
import homeImage from '../images/home.jpg'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Posts from './Posts';
import './main.css'

function Main() {
    return (

        <div>
            <Container>
                <Row>
                    <Col xs={9} sm={3} xl={3}>
                        <div className="category-image-container road-category">
                            <a href="Posts/road">
                                <img className="categoryImage" alt="Road trips" src={roadImage} />
                            </a>
                        </div>
                        <p className="categoryName">ճամփեքին</p>
                    </Col>
                    <Col xs={9} sm={3} xl={3}>
                        <div className="category-image-container home-category">
                            <a href="Posts/home">
                                <img className="categoryImage" alt="local trips" src={homeImage} />
                            </a>
                        </div>
                        <p className="categoryName">տանը</p>
                    </Col>
                    <Col xs={9} sm={5} xl={5}>
                        <div className="beautiful-text">քանի քամին չի փոխել ուղղությունը</div>
                    </Col>
                </Row>
            </Container>
            <Posts />
        </div>
    );
}

export default Main;
