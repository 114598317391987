import './App.css';
import './fonts.css';
import Header from './shared/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import PrivatePage from './pages/PrivatePage';
import Main from './pages/MainPage';
import BlogPostCreate from './pages/BlogPostCreate'
import Posts from './pages/Posts';
import BlogPostEdit from './pages/BlogPostEdit';
import BlogPost from './pages/BlogPost';
import Info from './pages/Info';

function App() {
  return (
    <div>
        <Header />
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/Login/:userId?" element={<Login />} />
            <Route path="/Private" element={<PrivatePage />} />
            <Route path="/Posts/Create" element={<BlogPostCreate />} />
            <Route path="/Post/Edit/:postId" element={<BlogPostEdit />} />
            <Route path="/Post/:postId" element={<BlogPost />} />
            <Route path="/Posts/:type?" element={<Posts />} />
            <Route path="/Info" element={<Info />} />
          </Routes>
        </Router>
    </div>
  )
}

export default App;
