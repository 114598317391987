import React from 'react';
import aboutImage from '../images/about.jpg'
import Button from 'react-bootstrap/Button'
import "./aboutme.css"

const AboutMe = () => {
    const socialLinks = [

    ];

    return (
        <div className="max-w-xl mx-auto p-4">
            <p className="about-title">Իմ մասին</p>

            <div className="image-container">
                <img
                    src={aboutImage}
                    alt="Photographer with camera"
                    className="image"
                />
            </div>

            <div className="prose mb-6">
                <p className="text-gray-700">
                Բարև, ես Գլյուկն եմ։ 

                <br/>
                <br/>

Քովիդի ամենաակտիվ ու աշխարհի ամենափակ ժամանակներում ես տեղափոխվեցի բավարական մի փոքրիկ քաղաք, որտեղ պատրաստվում էի բժշկությամբ զբաղվել։ Բայց շատ անսպասելի իմ տունը գտա հյուսիսում՝ սկանդինավյան սիրունագույն աշխարհում։ 
<br/>
<br/>

Այս բլոգում իմ անցած ճամփեքն են, ապրումներն ու մտքերը, ճամփորդությունները քաղաքներում, որոնց հատ-հատ սիրահարվել եմ, ադապտացիայի դժվարություններն ու ծանր դեպրեսիայից հետո կյանքին վերադառնալու իմ փորձերը։
                </p>
            </div>

            {/* <Button variant="outline-dark">ավելին</Button> */}

            <div className="w-4/5 mx-auto">
                <hr className="border-t border-gray-300" />
            </div>
        </div>
    );
};

export default AboutMe;